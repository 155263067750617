.contentContainer {
    margin-top: 15px;
}

.inputTextField {
    max-width: 450px;
}

.buttonBox {
    width: 250px;
    text-align: left;
}

.infoContainer {
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}

@media (max-width: 620px) {
    .infoContainer {
        flex-direction: column;
    }
}
