.stepIconRoot {
  color: #eaeaf0;
  display: flex;
  height: 22px;
  align-items: center;
}

.stepIconActive {
  color: #89bd23;
}

.stepIconCircle {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: currentColor;
}

.stepIconCompleted {
  color: #89bd23;
  z-index: 1;
  font-size: 18px;
}

.stepAlternativeLabel {
  top: 10;
  left: 'calc(-50% + 20px)';
  right: 'calc(50% + 20px)';
}

.stepLine {
  border-color: '#eaeaf0';
  border-top-width: 3;
  border-radius: 1;
}
