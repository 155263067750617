/* styles.css */

.infoContainer {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

@media (max-width: 620px) {
  .infoContainer {
    flex-direction: column;
  }
}

.buttonsContainer {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}

@media (max-width: 570px) {
  .buttonsContainer {
    flex-direction: column;
  }
}
