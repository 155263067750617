.header {
    display: flex;
    justify-content: space-between;
}

.contentContainer {
    margin-top: 15px;
}

.permsContainer {
    display: flex;
    flex-direction: column;
    width: 50%;
    background-color: #FAFAFA;
    padding: 20px 15px;
    border-radius: 8px;
}

@media (max-width: 570px) {
    .permsContainer {
        width: 100%;
    }
}
