.cardRoot {
    width: 8rem;
    min-width: 180px;
    height: 8.5rem;
    background: #FAFAFA;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    position: relative;
    cursor: pointer;
    transition: all 0.2s ease-out;
}

.cardRoot:hover {
    box-shadow: 0px 4px 8px rgba(38, 38, 38, 0.2);
    top: -4px;
    border: 1px solid #cccccc;
    background-color: white;
}

.cardRoot:before {
    content: "";
    position: absolute;
    z-index: -1;
    top: -16px;
    right: -16px;
    background: #00838d;
    height: 32px;
    width: 32px;
    border-radius: 50%;
    transform: scale(2);
    transform-origin: 50% 50%;
    transition: transform 0.15s ease-out;
}

.cardRoot:hover:before {
    transform: scale(2.15);
}

.avatar {
    width: 3.75rem;
    height: 3.75rem;
}

.name {
    text-transform: capitalize;
    font-weight: 600;
    font-size: 0.875rem;
    padding: 0 0.3rem;
    text-align: center;
    width: 100%;
    overflow-wrap: break-word;
}
