.popupRoot {
    width: 360px;
    position: relative;
    z-index: 4000;
    padding: 16px 30px 16px 16px;
    box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.2);
    display: flex;
}

.popupRoot + div {
    margin-top: 6px;
}

@media (max-width: 600px) {
    .popupRoot {
        width: 100%;
    }
}

.popupBox {
    flex: 1;
}

.description {
    margin-top: 4px;
    font-size: 14px;
    opacity: 0.8;
    line-height: 20px;
}

@media (max-width: 350px) {
    .description {
        font-size: 13px;
    }
}

.popupSpan {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 6px;
    animation: spanAnimation 7s;
}

@keyframes spanAnimation {
    0% {
        width: 0%;
    }
    100% {
        width: 100%;
    }
}

.strong {
    @media (max-width: 350px) {
        font-size: 12px;
    }
}

.button {
    position: absolute;
    right: 2%;
    top: 10px;
    opacity: 0.6;
    border: 0;
    background: transparent;
    color: inherit;
    cursor: pointer;
}

.popupContainer {
    position: absolute;
    right: 0;
    top: 0;
    padding: 30px;
    overflow: hidden;
}

@media (max-width: 600px) {
    .popupContainer {
        width: 100%;
    }
}

